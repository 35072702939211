<template>
  <section class="page-title page-title-bg page-title-center ptb-90">
    <div class="container">
      <h2 class="text-bold white-text font-40">About Us</h2>
      <ol class="breadcrumb">
        <li><a href="/">Home</a></li>
        <li class="active">About Us</li>
      </ol>
    </div>
  </section>

  <section class="section-padding">
    <div class="container">
      <div class="text-center mb-80">
        <h2 class="section-title text-capitalize">We Solve Business Problem</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p>
            Welcome to our technology and digital marketing company! We are a team of experts dedicated to helping
            businesses thrive in the digital landscape. With years of experience, we specialize in creating custom digital
            marketing strategies tailored to each client's unique needs. From website design and development to SEO and
            social media management, we provide comprehensive solutions to drive growth and increase brand visibility. Our
            mission is to deliver exceptional results while providing personalized support and outstanding customer
            service. Let us help take your business to the next level!
          </p>
        </div>
        <div class="col-md-6">
          <p>
            Our mission is to empower businesses with innovative technology and digital marketing solutions that drive
            growth and create impactful customer experiences. We strive to stay at the forefront of emerging technologies
            and help our clients achieve their goals by delivering cutting-edge strategies and exceptional results. Our
            ultimate aim is to build long-lasting partnerships and become a trusted partner in our clients' success.
          </p>
        </div>
      </div>
      <!-- <div class="video-intro mt-50">
        <img src="static/img/corporate/video-bg.jpg" class="img-responsive " alt="Image" />
        <a class="external-link popup-video" href="https://www.youtube.com/watch?v=A1CoJKju-YI" title=""><i
            class="material-icons">&#xE038;</i></a>
      </div> -->
    </div>
  </section>

  <!-- <section class="padding-bottom-80">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="thumb-carousel logo-thumb-right">
            <ul class="slides">
              <li data-thumb="static/img/client-logo/1.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
              <li data-thumb="static/img/client-logo/2.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
              <li data-thumb="static/img/client-logo/hp.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
              <li data-thumb="static/img/client-logo/4.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
              <li data-thumb="static/img/client-logo/google.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
              <li data-thumb="static/img/client-logo/dell.png">
                <div class="content">
                  <p>
                    Vivamus magna a nulla felis porttitor praesent ullamcorper
                    ut sed porttitor parturient per a sapien at sed quisque
                    vestibulum. Parturient ipsum parturient a condimentum
                    consequat donec dictum facilisis consectetur molestie
                    lobortis.
                  </p>
                  <div class="testimonial-meta brand-color">
                    Jonathan Doe
                    <span>Funder of TrendyTheme</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <hr />
  <section class="ptb-90">
    <div class="container">
      <div class="text-center mb-80">
        <h2 class="section-title text-uppercase">Our Super Heros</h2>
        <p class="section-sub">
          Quisque non erat mi. Etiam congue et augue sed tempus. Aenean sed
          ipsum luctus, scelerisque ipsum nec, iaculis justo. Sed at vestibulum
          purus, sit amet viverra diam nulla ac nisi rhoncus.
        </p>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="team-wrapper">
            <div class="team-img">
              <a href="/"><img src="static/img/team/team-7.jpg" class="img-responsive" alt="Image" /></a>
            </div>
            <div class="team-title">
              <h3><a href="/">Jonathan Smith</a></h3>
              <span>ceo &amp; founder</span>
              <p>
                A id a torquent tortor at lacus et donec platea eu scelerisque
                maecenas ac eros a adipiscing id lobortis cum lacus erat.
              </p>
            </div>
            <ul class="team-social-links list-inline text-center">
              <li>
                <a href="/"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-dribbble"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-envelope-o"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="team-wrapper">
            <div class="team-img">
              <a href="/"><img src="static/img/team/team-8.jpg" class="img-responsive" alt="Image" /></a>
            </div>
            <div class="team-title">
              <h3><a href="/">brauns hizzle doe</a></h3>
              <span>front end developer</span>
              <p>
                A id a torquent tortor at lacus et donec platea eu scelerisque
                maecenas ac eros a adipiscing id lobortis cum lacus erat.
              </p>
            </div>
            <ul class="team-social-links list-inline text-center">
              <li>
                <a href="/"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-dribbble"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-envelope-o"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="team-wrapper">
            <div class="team-img">
              <a href="/"><img src="static/img/team/team-9.jpg" class="img-responsive" alt="Image" /></a>
            </div>
            <div class="team-title">
              <h3><a href="/">eftakher doe</a></h3>
              <span>ui &amp; ux designer</span>
              <p>
                A id a torquent tortor at lacus et donec platea eu scelerisque
                maecenas ac eros a adipiscing id lobortis cum lacus erat.
              </p>
            </div>
            <ul class="team-social-links list-inline text-center">
              <li>
                <a href="/"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-dribbble"></i></a>
              </li>
              <li>
                <a href="/"><i class="fa fa-envelope-o"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>
<script>
export default {};
</script>
