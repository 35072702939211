<template>
  <div class="top-bar dark-bg lighten-2 visible-md visible-lg">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <ul class="list-inline social-top tt-animate btt">
            <!-- <li>
              <a href="javascript:;"><i class="fa fa-facebook"></i></a>
            </li>
            <li>
              <a href="javascript:;"><i class="fa fa-twitter"></i></a>
            </li>
            <li>
              <a href="javascript:;"><i class="fa fa-tumblr"></i></a>
            </li> -->
            <li>
              <a href="https://id.linkedin.com/company/digi-cinta-kreatif" target="_blank"><i class="fa fa-linkedin"></i></a>
            </li>
            <!-- <li>
              <a href="javascript:;"><i class="fa fa-dribbble"></i></a>
            </li>
            <li>
              <a href="javascript:;"><i class="fa fa-instagram"></i></a>
            </li>
            <li>
              <a href="javascript:;"><i class="fa fa-rss"></i></a>
            </li> -->
          </ul>
        </div>
        <div class="col-md-6 text-right">
          <ul class="topbar-cta no-margin">
            <li class="mr-20">
              <a href="mailto:business@digicinta.com" target="_blank"><i
                  class="material-icons mr-10">&#xE0B9;</i>business@digicinta.com</a>
            </li>
            <!-- <li>
              <a href="tel:+6287884457722"
                ><i class="material-icons mr-10">&#xE0CD;</i> +62
                878-8445-7722</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
