<template>
  <footer class="footer footer-four">
    <div class="primary-footer dark-bg lighten-1 text-center">
      <div class="container">
        <a href="#top" class="page-scroll btn-floating btn-large pink back-top waves-effect waves-light"
          data-section="#top">
          <i class="material-icons">&#xE316;</i>
        </a>
        <ul class="social-link tt-animate ltr mt-20">
          <!-- <li>
            <a href="javascript:;"><i class="fa fa-facebook"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="fa fa-twitter"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="fa fa-tumblr"></i></a>
          </li> -->
          <li>
            <a href="https://id.linkedin.com/company/digi-cinta-kreatif" target="_blank"><i class="fa fa-linkedin"></i></a>
          </li>
          <!-- <li>
            <a href="javascript:;"><i class="fa fa-dribbble"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="fa fa-instagram"></i></a>
          </li>
          <li>
            <a href="javascript:;"><i class="fa fa-rss"></i></a>
          </li> -->
        </ul>
        <hr class="mt-15" />
        <div class="row">
          <div class="col-md-12">
            <span class="copy-text">Copyright &copy; {{ year }} <a href="/">DigiCinta</a> &nbsp; | &nbsp;
              All Rights Reserved &nbsp;
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="secondary-footer dark-bg lighten-2 text-center">
      <div class="container">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About us</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
