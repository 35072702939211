<template>
  <section class="page-title page-title-bg page-title-center ptb-90">
    <div class="container">
      <h2 class="text-bold white-text font-40">Contact Us</h2>
      <ol class="breadcrumb">
        <li><a href="/">Home</a></li>
        <li class="active">Contact Us</li>
      </ol>
    </div>
  </section>

  <section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <form name="contact-form" id="contactForm" action="/" method="POST">
            <div class="row">
              <div class="col-md-6">
                <div class="input-field">
                  <input type="text" name="name" class="validate" id="name" />
                  <label for="name">Name</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-field">
                  <label class="sr-only" for="email">Email</label>
                  <input id="email" type="email" name="email" class="validate" />
                  <label for="email" data-error="wrong" data-success="right">Email</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-field">
                  <input id="phone" type="tel" name="phone" class="validate" />
                  <label for="phone">Phone Number</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-field">
                  <input id="website" type="text" name="website" class="validate" />
                  <label for="website">Your Website</label>
                </div>
              </div>
            </div>
            <div class="input-field">
              <textarea name="message" id="message" class="materialize-textarea"></textarea>
              <label for="message">Message</label>
            </div>
            <button type="submit" name="submit" class="waves-effect waves-light btn submit-button mt-30">
              Send Message
            </button>
          </form>
        </div>
        <div class="col-md-4 contact-info">
          <address>
            <i class="material-icons brand-color">&#xE8B4;</i>
            <div class="address">
              <p>Jakarta Selatan, Indonesia<br /></p>
            </div>
            <!-- <i class="material-icons brand-color">&#xE61C;</i> -->
            <!-- <div class="phone">
              <p>
                <a href="tel:+6287884457722">Phone: +62 878-8445-7722</a>
              </p>
            </div> -->
            <i class="material-icons brand-color">&#xE0B7;</i>
            <div class="mail">
              <p>
                <a href="mailto:business@digicinta.com" target="_blank">business@digicinta.com</a>
              </p>
            </div>
          </address>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
