<template>
  <section class="page-title page-title-bg page-title-center ptb-90">
    <div class="container">
      <h2 class="text-bold white-text font-40">Service</h2>
      <ol class="breadcrumb">
        <li><a href="/">Home</a></li>
        <li class="active">Service</li>
      </ol>
    </div>
  </section>

  <section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE0B7;</i>
            </div>
            <div class="desc">
              <h2>Creative Artist</h2>
              <p>
                We use our artistic talent and creativity to develop engaging visual content that can be used to promote a
                brand or product online. In today's digital age, consumers are inundated with content, and it can be
                challenging to grab their attention. This is where a creative artist can be invaluable.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE912;</i>
            </div>
            <div class="desc">
              <h2>Web Design & Development</h2>
              <p>
                If you're looking to build a modern business website, having a skilled web design and web development team
                is crucial. Our great team will not only create an aesthetically pleasing website but also ensure that
                it's user-friendly, functional, and optimized for search engines.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE8B8;</i>
            </div>
            <div class="desc">
              <h2>App Design & App Development</h2>
              <p>
                Our App Design & App Development team is a crucial component of a modern digital business. With our skills
                and expertise, we are responsible for creating user-friendly and visually appealing mobile applications
                that can meet the needs of today's tech-savvy consumers.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE323;</i>
            </div>
            <div class="desc">
              <h2>Product Research</h2>
              <p>
                We are responsible for conducting extensive research and analysis of consumer needs, market trends, and
                competitor offerings to identify potential new product opportunities.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE412;</i>
            </div>
            <div class="desc">
              <h2>Professional Photography</h2>
              <p>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus id quod maxime placeat facere possimus.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="featured-item hover-outline brand-hover radius-4">
            <div class="icon">
              <i class="material-icons colored brand-icon">&#xE02C;</i>
            </div>
            <div class="desc">
              <h2>Moting Graphics Design</h2>
              <p>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus id quod maxime placeat facere possimus.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <section class="section-padding chart-wrapper overlay">
    <div class="container">
      <div class="row mb-50">
        <div class="col-md-6">
          <h2 class="white-text mb-sm-30">
            We are continually working on improving materialize for more feature
            and demos
          </h2>
        </div>
        <div class="col-md-6">
          <p class="white-text">
            Proactively maximize exceptional infrastructures via vertical
            initiatives. Continually benchmark cross-unit technology and
            efficient solutions. Distinctively monetize professional initiatives
            with client-centric e-commerce. Synergistically embrace performance
            based benefits and professional strategic theme areas.
            Enthusiastically disseminate sustainable technology before user
            friendly web services.
          </p>
        </div>
      </div>
      <div class="row chart-skill-wrapper text-center">
        <div class="col-md-3 col-sm-6 mb-sm-30">
          <div class="chart" data-percent="79">
            <span class="percent white-text">79</span>
            <div class="chart-text">
              <span class="white-text">Concept</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-sm-30">
          <div class="chart" data-percent="65">
            <span class="percent white-text">65</span>
            <div class="chart-text">
              <span class="white-text">Design</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-sm-30">
          <div class="chart" data-percent="89">
            <span class="percent white-text">89</span>
            <div class="chart-text">
              <span class="white-text">Develompent</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-sm-30">
          <div class="chart" data-percent="85">
            <span class="percent white-text">85</span>
            <div class="chart-text">
              <span class="white-text">Finish</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
