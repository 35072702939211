<template>
  <header id="header" class="tt-nav nav-border-bottom">
    <div class="header-sticky light-header ">
      <div class="container">
        <div id="materialize-menu" class="menuzord">
          <a href="/" class="logo-brand">
            <img src="static/img/corporate/logo-digicinta.png" alt="" />
          </a>

          <ul class="menuzord-menu pull-right">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {};
</script>
