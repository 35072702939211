import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Services from './components/Services';

import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        name: 'Home',
        component: Home,
        path: ''
    },
    {
        name: 'About',
        component: About,
        path: '/about'
    },
    {
        name: 'Contact',
        component: Contact,
        path: '/contact'
    },
    {
        name: 'Portfolio',
        component: Portfolio,
        path: '/portfolio'
    },
    {
        name: 'Services',
        component: Services,
        path: '/services'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router