<template>
  <div id="preloader">
    <div class="preloader-position">
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
