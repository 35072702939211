<template>
  <TopHeader />
  <HeaderNav />
  <router-view />
  <Footer />
  <PreLoader />
</template>

<script>
import TopHeader from "./components/Common/TopHeader.vue";
import HeaderNav from "./components/Common/HeaderNav.vue";
import Footer from "./components/Common/Footer.vue";
import PreLoader from "./components/Common/PreLoader.vue";
export default {
  name: "App",
  components: {
    TopHeader: TopHeader,
    HeaderNav: HeaderNav,
    Footer: Footer,
    PreLoader: PreLoader,
  },
};
</script>
<style></style>
