<template>
  <section class="page-title page-title-bg page-title-center ptb-90">
    <div class="container">
      <h2 class="text-bold white-text font-40">Portfolio</h2>
      <ol class="breadcrumb">
        <li><a href="/">Home</a></li>
        <li><a href="/">Portfolio</a></li>
        <li class="active">Portfolio 4 Column</li>
      </ol>
    </div>
  </section>

  <section class="section-padding">
    <div class="container">
      <div class="text-center mb-50">
        <h2 class="section-title text-uppercase">Our Works</h2>
        <p class="section-sub">
          Quisque non erat mi. Etiam congue et augue sed tempus. Aenean sed
          ipsum luctus, scelerisque ipsum nec, iaculis justo. Sed at vestibulum
          purus, sit amet viverra diam. Nulla ac nisi rhoncus,
        </p>
      </div>
      <div class="portfolio-container text-center">
        <ul class="portfolio-filter brand-filter">
          <li class="active waves-effect waves-light" data-group="all">All</li>
          <li class=" waves-effect waves-light" data-group="websites">
            Websites
          </li>
          <li class=" waves-effect waves-light" data-group="branding">
            Branding
          </li>
          <li class=" waves-effect waves-light" data-group="marketing">
            Marketing
          </li>
          <li class=" waves-effect waves-light" data-group="photography">
            Photography
          </li>
        </ul>
        <div class="portfolio portfolio-with-title col-4 gutter mtb-50">
          <div class="portfolio-item" data-groups='["all", "branding", "photography"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-1.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-1.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2>
                  <a href="/">Creative Design</a>
                </h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "marketing", "websites"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-2.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-2.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2><a href="/">Brand Design</a></h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "photography", "branding"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-3.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-3.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2><a href="/">Web Design</a></h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "websites", "branding"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-4.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-4.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2><a href="/">Game Design</a></h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "photography", "marketing"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-5.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-5.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2>
                  <a href="/">Interface Design</a>
                </h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "websites",  "marketing"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-6.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-6.jpg" class="tt-lightbox" title="">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2><a href="/">Logo Design</a></h2>
                <p><a href="/">Branding</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "websites", "photography"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-7.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-7.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2>
                  <a href="/">Dashboard Design</a>
                </h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
          <div class="portfolio-item" data-groups='["all", "branding", "marketing"]'>
            <div class="portfolio-wrapper">
              <div class="thumb">
                <div class="bg-overlay"></div>
                <img src="static/img/portfolio/portfolio-8.jpg" alt="" />
                <div class="portfolio-intro">
                  <div class="action-btn">
                    <a href="static/img/portfolio/portfolio-8.jpg" class="tt-lightbox" title="iOS Game Design">
                      <i class="fa fa-search"></i></a>
                  </div>
                </div>
              </div>
              <div class="portfolio-title">
                <h2>
                  <a href="/">Character Design</a>
                </h2>
                <p><a href="/">iOS Design</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="load-more-button text-center">
          <a class="waves-effect waves-light btn btn-large pink">
            <i class="fa fa-spinner left"></i> Load More</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
