<template>
  <section class="corporate-banner-1 bg-fixed parallax-bg height-650 valign-wrapper" data-stellar-background-ratio="0.5">
    <div class="valign-cell">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-offset-6">
            <h1 class="intro-title text-capitalize">Business Service Provider</h1>
            <p class="lead" style="color:black;">
              Specializing in software delivery and licensing , digital marketing, game development, customer experiences
              & brand development, we combine craftsmanship.
            </p>
            <a href="/services" class="btn btn-lg waves-effect waves-light mt-20">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-padding">
    <div class="container">
      <div class="text-center mb-80">
        <h2 class="section-title text-uppercase">What We Do</h2>
        <p class="section-sub">
          Our approach to assisting businesses in creating digital products involves integrating strategy, design, and
          custom software development for successful outcomes.
        </p>
      </div>
      <div class="row no-gutter">
        <div class="col-md-3 col-sm-6">
          <div class="featured-box dark-bg lighten-2 text-center z-depth-1">
            <div class="featured-wrapper">
              <div class="intro-header">
                <i class="material-icons white-text">&#xE53B;</i>
                <h2 class="white-text">Creative Gaming Contents</h2>
              </div>
              <div class="content white-text">
                <p>
                  We use our artistic talent and creativity to develop engaging visual gaming content that can be used to promote a brand or product online. In today's digital age, consumers are inundated with gaming content, and it can be challenging to grab their attention.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="featured-box green-bg text-center z-depth-1">
            <div class="featured-wrapper">
              <div class="intro-header">
                <i class="material-icons white-text">&#xE051;</i>
                <h2 class="white-text">Intelligent Automation</h2>
              </div>
              <div class="content white-text">
                <p>
                  We build modern business software. Our great team will not only create & deliver an aesthetically pleasing intelligent software but also ensure that it's user-friendly, functional, and optimized for recommendation engines.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="featured-box dark-bg lighten-2 text-center z-depth-1">
            <div class="featured-wrapper">
              <div class="intro-header">
                <i class="material-icons white-text">&#xE323;</i>
                <h2 class="white-text">App Design & Development</h2>
              </div>
              <div class="content white-text">
                <p>
                  Our App Design & App Development team is a crucial component of a modern digital business. With our
                  skills and expertise, we are responsible for creating user-friendly and visually appealing mobile
                  applications that can meet the needs of today's tech-savvy consumers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="featured-box green-bg text-center z-depth-1">
            <div class="featured-wrapper">
              <div class="intro-header">
                <i class="material-icons white-text">&#xE0B9;</i>
                <h2 class="white-text">Product Research</h2>
              </div>
              <div class="content white-text">
                <p>
                  We are responsible for conducting extensive research and analysis of consumer needs, market trends, and
                  competitor offerings to identify potential new product opportunities. We work closely with product
                  development, marketing, and sales to ensure products meet demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-padding gray-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6 counter-section mb-sm-50">
          <ul class="count-plus-box">
            <li>
              <i class="material-icons brand-color">&#xE90F;</i>
              <span class="timer">90</span>
              <span class="count-description">Ideas</span>
            </li>
            <li>
              <i class="material-icons brand-color">&#xE863;</i>
              <span class="timer">65</span>
              <span class="count-description">Experiments</span>
            </li>
            <li>
              <i class="material-icons brand-color">&#xE8F8;</i>
              <span class="timer">21</span>
              <span class="count-description">Project Run</span>
            </li>
            <li>
              <i class="material-icons brand-color">&#xE87E;</i>
              <span class="timer">14</span>
              <span class="count-description">Success</span>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <h2 class="text-bold text-uppercase mb-30">A little bit about us</h2>
          <div class="border-tab primary-nav corporate-demo-tab">
            <ul class="nav nav-tabs nav-justified" role="tablist">
              <li role="presentation" class="active">
                <a href="#tab-10" class="waves-effect waves-dark" role="tab" data-toggle="tab">About us</a>
              </li>
              <li role="presentation" style="display: none;">
                <a href="#tab-11" class="waves-effect waves-dark" role="tab" data-toggle="tab">What We Do</a>
              </li>
              <li role="presentation">
                <a href="#tab-12" class="waves-effect waves-dark" role="tab" data-toggle="tab">Our Mission</a>
              </li>
            </ul>

            <div class="panel-body">
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane fade in active" id="tab-10">
                  <p>
                    Welcome to our technology and digital marketing company! We are a team of experts dedicated to helping
                    businesses thrive in the digital landscape. With years of experience, we specialize in creating custom
                    digital marketing strategies tailored to each client's unique needs. From website design and
                    development to SEO and social media management, we provide comprehensive solutions to drive growth and
                    increase brand visibility. Our mission is to deliver exceptional results while providing personalized
                    support and outstanding customer service. Let us help take your business to the next level!
                  </p>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="tab-11">
                  <p>
                    Our approach to assisting businesses in creating digital products involves integrating strategy,
                    design, and custom software development for successful outcomes.
                  </p>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="tab-12">
                  <p>
                    Our mission is to empower businesses with innovative technology and digital marketing solutions that
                    drive growth and create impactful customer experiences. We strive to stay at the forefront of emerging
                    technologies and help our clients achieve their goals by delivering cutting-edge strategies and
                    exceptional results. Our ultimate aim is to build long-lasting partnerships and become a trusted
                    partner in our clients' success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-padding">
    <div class="container">
      <div class="text-center mb-80">
        <h2 class="section-title text-uppercase">Why Choose Us</h2>
        <p class="section-sub">
          When you choose us, you can trust that you are partnering with a company that is driven by results and committed
          to your success.
        </p>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="vertical-tab">
            <div class="row">
              <div class="col-sm-4">
                <ul class="nav nav-tabs nav-stacked" role="tablist">
                  <li role="presentation" class="active">
                    <a href="#tab-5" class="waves-effect waves-light" role="tab" data-toggle="tab">About us</a>
                  </li>
                  <li role="presentation" style="display: none;">
                    <a href="#tab-6" class="waves-effect waves-light" role="tab" data-toggle="tab">What We Do</a>
                  </li>
                  <li role="presentation">
                    <a href="#tab-7" class="waves-effect waves-light" role="tab" data-toggle="tab">Our Mission</a>
                  </li>
                  <li role="presentation">
                    <a href="#tab-8" class="waves-effect waves-light" role="tab" data-toggle="tab">Our Team</a>
                  </li>
                </ul>
              </div>
              <div class="col-sm-8">
                <div class="panel-body">
                  <div class="tab-content">
                    <div role="tabpanel" class="tab-pane fade in active" id="tab-5">
                      <h2>About Us</h2>
                      <p>
                        Welcome to our technology and digital marketing company! We are a team of experts dedicated to
                        helping businesses thrive in the digital landscape. With years of experience, we specialize in
                        creating custom digital marketing strategies tailored to each client's unique needs. From website
                        design and development to SEO and social media management, we provide comprehensive solutions to
                        drive growth and increase brand visibility. Our mission is to deliver exceptional results while
                        providing personalized support and outstanding customer service. Let us help take your business to
                        the next level!
                      </p>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="tab-6">
                      <h2>What We Do</h2>
                      <p>
                        Our approach to assisting businesses in creating digital products involves integrating strategy,
                        design, and custom software development for successful outcomes.
                      </p>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="tab-7">
                      <h2>Our Mission</h2>
                      <p>
                        Our mission is to empower businesses with innovative technology and digital marketing solutions
                        that drive growth and create impactful customer experiences. We strive to stay at the forefront of
                        emerging technologies and help our clients achieve their goals by delivering cutting-edge
                        strategies and exceptional results. Our ultimate aim is to build long-lasting partnerships and
                        become a trusted partner in our clients' success.
                      </p>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" id="tab-8">
                      <h2>Our Team</h2>
                      <p>
                        "Our team" is the driving force behind our software technology & digital marketing company. We are
                        a group of skilled and passionate individuals who are dedicated to delivering the best possible
                        solutions for our clients, through a combination of expertise, collaboration, and a commitment to
                        excellence.
                        Our team is dedicated to delivering high-quality solutions that exceed our clients' expectations.
                        We take pride in our work and strive to create solutions that not only meet the functional
                        requirements of our clients, but also provide an exceptional user experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h2 class="text-bold ">Our Skills</h2>
          <div class="progress-section">
            <span class="progress-title">Service Delivery Platform (Telco & ISP)</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="98"
                aria-valuemin="0" aria-valuemax="100">
                <span>98%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Mobile app development</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="86"
                aria-valuemin="0" aria-valuemax="100">
                <span>86%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Graphic design</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="86"
                aria-valuemin="0" aria-valuemax="100">
                <span>86%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Cloud computing</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="95"
                aria-valuemin="0" aria-valuemax="100">
                <span>95%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Software architecture and design</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="90"
                aria-valuemin="0" aria-valuemax="100">
                <span>90%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Website development</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="92"
                aria-valuemin="0" aria-valuemax="100">
                <span>92%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Cyber Security</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="90"
                aria-valuemin="0" aria-valuemax="100">
                <span>90%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Testing and quality assurance</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="96"
                aria-valuemin="0" aria-valuemax="100">
                <span>96%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Search engine optimization (SEO)</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="90"
                aria-valuemin="0" aria-valuemax="100">
                <span>90%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Social media marketing</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="91"
                aria-valuemin="0" aria-valuemax="100">
                <span>91%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Content creation</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="84"
                aria-valuemin="0" aria-valuemax="100">
                <span>84%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">Analytics and reporting</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="89"
                aria-valuemin="0" aria-valuemax="100">
                <span>89%</span>
              </div>
            </div>
          </div>
          <div class="progress-section">
            <span class="progress-title">E-commerce solutions</span>
            <div class="progress">
              <div class="progress-bar green-bg six-sec-ease-in-out" role="progressbar" aria-valuenow="80"
                aria-valuemin="0" aria-valuemax="100">
                <span>80%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ptb-50 gray-bg">
    <div class="container-fluid">
      <div class="row equal-height-row">
        <div class="col-md-4 consultancy-man equal-height-column">
          <img src="static/img/corporate/contact-home.webp" alt="" />
        </div>
        <div class="col-md-8">
          <div class=" equal-height-column valign-wrapper">
            <div class="valign-cell consultancy-wrapper">
              <h2 class="text-bold">Do You need business consultancy?</h2>
              <p>
                Get in touch with us. We would love to answer your questions & we will explain about our products or
                services.
              </p>
              <form name="contact-form" id="contactForm" action="/" method="POST">
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-field">
                      <input type="text" name="name" class="validate" id="name" />
                      <label for="name">First Name*</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-field">
                      <input id="email" type="email" name="email" class="validate" />
                      <label for="email" data-error="wrong" data-success="right">Email Address*</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-field">
                      <input type="text" name="phone" class="validate" id="phone" />
                      <label for="phone">Phone Number</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-field">
                      <input id="web" type="text" name="web" class="validate" />
                      <label for="web" data-error="wrong" data-success="right">Your Website</label>
                    </div>
                  </div>
                </div>
                <div class="input-field">
                  <textarea name="message" id="message" class="materialize-textarea"></textarea>
                  <label for="message">Your Message</label>
                </div>
                <button type="submit" name="submit" class="waves-effect waves-light btn right mt-30">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
.featured-box:hover .featured-wrapper .intro-header {
  display: none;
}
</style>